// App.tsx
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'sonner';
import { HelmetProvider } from 'react-helmet-async';
import { Navigation } from './components/Navigation';
import { Hero } from './components/Hero';
import { About } from './components/About';
import { FeaturedWork } from './components/FeaturedWork';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { CookieConsent } from './components/CookieConsent';
import { ReadingProgress } from './components/ReadingProgress';
import { BackToTop } from './components/BackToTop';
import Resume from './pages/Resume';
import Blog from './pages/Blog';
import BlogPost from './components/BlogPost'; // Ensure this path is correct
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Publications from './pages/Publications';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import CookiePolicy from './pages/CookiePolicy';
import Sitemap from './pages/Sitemap';

const MainContent = () => (
  <>
    <Hero />
    <About />
    <FeaturedWork />
    <Contact />
  </>
);

const App = () => {
  return (
    <HelmetProvider>
      <div className="min-h-screen bg-background">
        <ReadingProgress />
        <Navigation />

        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetails />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/sitemap" element={<Sitemap />} />
        </Routes>
        
        <Footer />
        <BackToTop />
        <CookieConsent />
        <Toaster 
          position="bottom-right"
          toastOptions={{
            style: {
              background: '#2B3187',
              color: '#F7F8F8',
              border: '1px solid rgba(43, 49, 135, 0.2)',
            },
          }}
        />
      </div>
    </HelmetProvider>
  );
};

export default App;
