import { Mail, Linkedin } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";
import { AnimatedCard } from './AnimatedCard';

export const Contact = () => {
  return (
    <section id="contact" className="py-20 px-4">
      <div className="container mx-auto max-w-4xl">
        <AnimatedCard>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-text mb-8">Get in Touch</h2>
            <p className="text-text/60 text-lg max-w-2xl mx-auto mb-12">
            Have a question, or just want to say hi? Let’s connect.
            </p>
            <div className="flex flex-wrap justify-center gap-6">
              <a 
                href="mailto:zein@zeinalamah.com"
                className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
              >
                <Mail size={20} />
                Email Me
              </a>
              <a 
                href="https://www.linkedin.com/in/zein-alamah/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
              >
                <Linkedin size={20} />
                Connect on LinkedIn
              </a>
              <a 
                href="https://x.com/zein_alamah"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
              >
                <FaXTwitter size={20} />
                Follow on X
              </a>
            </div>
          </div>
        </AnimatedCard>
      </div>
    </section>
  );
};