import { useState } from 'react';
import { AnimatedCard } from './AnimatedCard';
import { BlogCard } from './BlogCard';
import { ProjectCard } from './ProjectCard';
import { PublicationCard } from './PublicationCard';
import { blogPosts } from '../data/blogPosts';
import { projects } from '../data/projects';
import { publications } from '../data/publications';

type WorkType = 'all' | 'articles' | 'projects' | 'publications';

export const FeaturedWork = () => {
  const [activeType, setActiveType] = useState<WorkType>('all');

  // Helper to sort by date
  const sortByDate = <T extends { date: string }>(items: T[]) =>
    [...items].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // Get the latest 3 items for each category
  const featuredArticles = sortByDate(blogPosts).slice(0, 3);
  const featuredProjects = sortByDate(projects).slice(0, 3);
  const featuredPublications = sortByDate(publications).slice(0, 3);

  // Combine all items and sort for the "all" category
  const allFeatured = sortByDate([
    ...blogPosts,
    ...projects,
    ...publications,
  ]).slice(0, 3);

  const renderContent = () => {
    switch (activeType) {
      case 'articles':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredArticles.map((post, index) => (
              <AnimatedCard key={post.id} delay={index * 0.1}>
                <BlogCard post={post} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      case 'projects':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredProjects.map((project, index) => (
              <AnimatedCard key={project.id} delay={index * 0.1}>
                <ProjectCard project={project} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      case 'publications':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredPublications.map((publication, index) => (
              <AnimatedCard key={publication.id} delay={index * 0.1}>
                <PublicationCard publication={publication} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      default:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {allFeatured.map((item, index) => {
              if ('category' in item) {
                return (
                  <AnimatedCard key={item.id} delay={index * 0.1}>
                    <BlogCard post={item as Required<typeof item>} showSocial />
                  </AnimatedCard>
                );
              } else if ('technologies' in item) {
                return (
                  <AnimatedCard key={item.id} delay={index * 0.1}>
                    <ProjectCard project={item} showSocial />
                  </AnimatedCard>
                );
              } else {
                return (
                  <AnimatedCard key={item.id} delay={index * 0.1}>
                    <PublicationCard publication={item} showSocial />
                  </AnimatedCard>
                );
              }
            })}
          </div>
        );
    }
  };

  return (
    <section className="py-20 px-4">
      <div className="container mx-auto">
        <AnimatedCard>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-text mb-4">Featured Work</h2>
            <p className="text-text/60 text-lg max-w-2xl mx-auto">
              Explore my latest research, projects, and insights.
            </p>
          </div>

          <div className="flex justify-center gap-4 mb-12">
            {[
              { type: 'all', label: 'All' },
              { type: 'articles', label: 'Articles' },
              { type: 'projects', label: 'Projects' },
              { type: 'publications', label: 'Publications' },
            ].map(({ type, label }) => (
              <button
                key={type}
                onClick={() => setActiveType(type as WorkType)}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeType === type
                    ? 'bg-primary text-text'
                    : 'bg-primary/20 text-text hover:bg-primary/30'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </AnimatedCard>

        {renderContent()}
      </div>
    </section>
  );
};
