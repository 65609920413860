import { Link } from 'react-router-dom';
import { Github, Linkedin, Mail } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-8 px-4 border-t border-primary/20">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between gap-8 mb-8">
          <div>
            <h3 className="text-text font-bold mb-4">Navigation</h3>
            <div className="grid grid-cols-2 gap-x-12 gap-y-2">
              <Link 
                to="/"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Home
              </Link>
              <Link 
                to="/blog"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Articles
              </Link>
              <Link 
                to="/projects"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Projects
              </Link>
              <Link 
                to="/publications"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Publications
              </Link>
              <Link 
                to="/resume"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Resume
              </Link>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4">Contact</h3>
            <div className="grid gap-y-2">
              <a 
                href="mailto:zein@zeinalamah.com"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                zein@zeinalamah.com
              </a>
              <a 
                href="https://www.linkedin.com/in/zein-alamah/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                LinkedIn Profile
              </a>
              <a 
                href="https://x.com/zein_alamah"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                X Profile
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4">Legal</h3>
            <div className="grid gap-y-2">
              <Link 
                to="/privacy-policy"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Privacy Policy
              </Link>
              <Link 
                to="/terms"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Terms
              </Link>
              <Link 
                to="/cookie-policy"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Cookie Policy
              </Link>
              <Link 
                to="/sitemap"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Sitemap
              </Link>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4">Security</h3>
            <div className="grid gap-y-2">
              <a 
                href="/security.txt"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Security Policy
              </a>
              <a 
                href="mailto:security@zeinalamah.com"
                className="text-text/60 hover:text-text transition-colors whitespace-nowrap"
              >
                Report an Issue
              </a>
            </div>
          </div>
        </div>
        
        <div className="text-center text-text/60 pt-4 border-t border-primary/20">
          <p>© {currentYear} Zein Alamah. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};