import { LazyImage } from './LazyImage';
import { SocialShare } from './SocialShare';
import { CardMeta } from './CardMeta';

interface Publication {
  id: string;
  title: string;
  journal: string;
  date: string;
  link: string;
  abstract: string;
  image: string;
  type: 'academic' | 'non-academic';
  tags: string[];
}

interface PublicationCardProps {
  publication: Publication;
  showSocial?: boolean;
}

export const PublicationCard = ({ publication, showSocial = false }: PublicationCardProps) => {
  if (!publication) return null;

  return (
    <article className="bg-primary/5 rounded-xl overflow-hidden border border-primary/20 hover:border-primary transition-colors h-full flex flex-col">
      <a
        href={publication.link}
        target="_blank"
        rel="noopener noreferrer"
        className="block group"
      >
        <div className="relative h-48 overflow-hidden">
          <LazyImage
            src={publication.image}
            alt={publication.title}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute top-4 left-4 bg-primary/90 text-text px-3 py-1 rounded-full text-sm">
            {publication.type === 'academic' ? 'Academic' : 'Non-Academic'}
          </div>
        </div>
      </a>

      <div className="p-6 flex flex-col flex-1">
        <div className="mb-4">
          <a
            href={publication.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-bold text-text hover:text-primary transition-colors block mb-3"
          >
            {publication.title}
          </a>
          <p className="text-text/60 line-clamp-3">
            {publication.abstract}
          </p>
        </div>

        <div className="mt-auto pt-4">
          <CardMeta
            date={publication.date}
            journal={publication.journal}
            tags={publication.tags}
          />

          {showSocial && (
            <div className="mt-4 pt-4 border-t border-primary/20">
              <SocialShare
                url={publication.link}
                title={publication.title}
                summary={publication.abstract}
              />
            </div>
          )}
        </div>
      </div>
    </article>
  );
};
