import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

interface ResumePublication {
  title: string;
  journal: string;
  date: string;
  link: string;
  abstract: string;
}

interface ResumePublicationCardProps {
  publication: ResumePublication;
  isExpanded: boolean;
  onToggle: () => void;
}

export const ResumePublicationCard = ({ publication, isExpanded, onToggle }: ResumePublicationCardProps) => {
  return (
    <div 
      className="p-6 bg-primary/20 hover:bg-primary rounded-xl transition-all cursor-pointer border border-primary/20"
      onClick={onToggle}
    >
      <div className="flex justify-between items-start">
        <div>
          <a 
            href={publication.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-bold text-text hover:text-text/80 transition-colors block mb-2"
            onClick={(e) => e.stopPropagation()}
          >
            {publication.title}
          </a>
          <p className="text-text/60">
            {publication.journal} • {publication.date}
          </p>
          <p className="text-text/80 text-sm mt-2">
            {isExpanded ? 'Click to collapse' : 'Click to view abstract'}
          </p>
        </div>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="text-text" />
        </motion.div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4"
          >
            <p className="text-text/80">{publication.abstract}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};