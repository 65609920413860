import { useParams } from 'react-router-dom';
import { AnimatedCard } from './AnimatedCard';
import { SocialShare } from './SocialShare';
import { LazyImage } from './LazyImage';
import { SEO } from './SEO';
import { blogPosts } from '../data/blogPosts';
import { Calendar, Clock, Tag } from 'lucide-react';

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(p => p.id === id);

  if (!post) {
    return (
      <div className="min-h-screen bg-background py-24 px-4">
        <div className="container mx-auto max-w-4xl text-center">
          <h1 className="text-4xl font-bold text-text mb-4">Post Not Found</h1>
          <p className="text-text/60">The article you're looking for doesn't exist.</p>
        </div>
      </div>
    );
  }

  const postUrl = window.location.href;

  // JSON-LD structured data for SEO
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "image": post.image,
    "datePublished": post.date,
    "author": {
      "@type": "Person",
      "name": "Zein Alamah"
    },
    "description": post.excerpt,
    "keywords": post.tags.join(", ")
  };

  return (
    <>
      <SEO
        title={post.title}
        description={post.excerpt}
        type="article"
        image={post.image}
        article={true}
        publishedTime={post.date}
        tags={post.tags}
        jsonLd={jsonLd}
      />

      <div className="min-h-screen bg-background py-24 px-4">
        <article className="container mx-auto max-w-4xl">
          <AnimatedCard>
            <header className="mb-8">
              <h1 className="text-4xl md:text-5xl font-bold text-text mb-6">
                {post.title}
              </h1>

              <div className="flex flex-wrap items-center gap-6 text-text/60 mb-6">
                <div className="flex items-center gap-2">
                  <Calendar size={20} />
                  {post.date}
                </div>
                <div className="flex items-center gap-2">
                  <Clock size={20} />
                  {post.readTime}
                </div>
                <div className="flex-1" />
                <SocialShare
                  url={postUrl}
                  title={post.title}
                  summary={post.excerpt}
                />
              </div>

              <div className="flex flex-wrap gap-2 mb-8">
                {post.tags.map((tag) => (
                  <span
                    key={tag}
                    className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-text/80 text-sm"
                  >
                    <Tag size={12} className="mr-1" />
                    {tag}
                  </span>
                ))}
              </div>
            </header>

            <div className="mb-8 rounded-xl overflow-hidden">
              <LazyImage
                src={post.image}
                alt={post.title}
                className="w-full h-[400px] object-cover"
              />
            </div>

            {/* Render content as HTML */}
            <div
              className="prose prose-lg prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: post.content || '' }}
            ></div>

            <footer className="mt-12 pt-8 border-t border-primary/20">
              <SocialShare
                url={postUrl}
                title={post.title}
                summary={post.excerpt}
              />
            </footer>
          </AnimatedCard>
        </article>
      </div>
    </>
  );
};

export default BlogPost;
