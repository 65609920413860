import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Linkedin, Download, ChevronDown } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";
import { AnimatedCard } from '../components/AnimatedCard';
import { ResumePublicationCard } from '../components/ResumePublicationCard';

const Resume = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeExperience, setActiveExperience] = useState<number | null>(null);
  const [activeEducation, setActiveEducation] = useState<number | null>(null);
  const [activeCertification, setActiveCertification] = useState<number | null>(null);
  const [activePublication, setActivePublication] = useState<number | null>(null);

  const experiences = [
    {
      title: "Business Strategy Consultant and Data Analyst",
      company: "Eco-Vector Consultancy (France)",
      period: "Aug 2024 – Dec 2024",
      details: [
        "Assessed factory operations and retail outlets for an F&B corporation, identifying efficiency and performance improvement opportunities.",
        "Evaluated financial, managerial, and economic performance with senior leadership, contributing to organizational restructuring.",
        "Designed two strategic frameworks and an internal selection process to enhance decision-making and operational efficiency.",
        "Processed and analyzed datasets with Python, generating 100+ visualizations to inform business strategies.",
        "Contributed to 20+ reports and presentations, delivering actionable insights to executives."
      ]
    },
    {
      title: "Economic and Business Management Consultant",
      company: "Independent Consultant",
      period: "April 2024 – July 2024",
      details: [
        "Developed tokenomics strategies using Python, collaborating with developers to implement smart contracts.",
        "Authored a whitepaper and strategic plan, driving community growth to 4,500 members.",
        "Led an international remote team, ensuring seamless project execution and alignment with objectives.",
        "Secured key partnerships through strategic negotiations to support project sustainability."
      ]
    },
    {
      title: "Business Strategy and Evaluation Consultant",
      company: "Eco-Vector Consultancy (France)",
      period: "Oct 2023 – April 2024",
      details: [
        "Designed an impact evaluation methodology and developed 100+ metrics for regional social development initiatives.",
        "Led strategic planning and creative ideation sessions to guide organizational decision-making.",
        "Drafted a strategic planning and management proposal for an F&B corporation."
      ]
    },
    {
      title: "Research Assistant",
      company: "Lebanese American University",
      period: "Sep 2022 – May 2024",
      details: [
        "Published three peer-reviewed articles and contributed to three additional papers under revision.",
        "Developed Stata scripts for econometric and statistical analysis, including custom-made commands.",
        "Performed data cleansing and preparation using Python and Stata.",
        "Drafted and revised research documents, including articles, proposals, and internal communications.",
        "Collaborated on internal strategies and event preparation for the Economics Department."
      ]
    },
    {
      title: "Managing Cofounder",
      company: "Cryptonic Enterprises LLP",
      period: "July 2021 – March 2022",
      details: [
        "Managed a multicultural team of five, overseeing project execution and strategic direction.",
        "Conducted market research on cryptoassets and authored whitepapers to support decision-making.",
        "Identified and mitigated project risks, facilitating weekly strategy meetings."
      ]
    }
  ];
 
  const education = [
    {
      degree: "Master of Arts in Applied Economics",
      institution: "Lebanese American University",
      period: "January 2022 – May 2024",
      details: [
        "GPA: 3.78/4",
        "MA Project: From Wall Street to Crypto Street: Ethereum’s Take on the Monday Effect"
      ]
    },
    {
      degree: "Master of Science in Finance and Financial Institutions",
      institution: "Lebanese University",
      period: "December 2019 – December 2022",
      details: [
        "GPA: 3.54/4",
        "Thesis: The Impact of Ethereum on Portfolio Performance (Distinction)"
      ]
    },
    {
      degree: "Bachelor of Arts in Finance with Microfinance",
      institution: "Arab Open University",
      period: "June 2016 – July 2019",
      details: [
        "First Class Honors, GPA: 3.68/4"
      ]
    }
  ];
  

  const certifications = [
    {
      title: "Financial Modeling and Valuation Analyst (FMVA)",
      organization: "Corporate Finance Institute",
      date: "January 2020"
    },
    {
      title: "Chartered Financial Analyst Level 1",
      organization: "CFA Institute",
      date: "August 2019",
      details: "Passed the CFA Level 1 exam"
    }
  ];

  const awards = [
    {
      title: "IMF Youth Fellowship",
      description: "Selected to attend the 2023 IMF and World Bank Annual Meetings",
      year: "October 2023"
    },
    {
      title: "LAU Outstanding Researcher Award",
      description: "Outstanding Researcher in Social Science",
      year: "April 2024"
    }
  ];

  const publications = [
    {
      title: "The role of international research collaboration and faculty related factors in publication citations: Evidence from Lebanon",
      journal: "Economies",
      date: "2023",
      link: "https://www.mdpi.com/2227-7099/11/3/90",
      abstract: "The importance of international collaboration in the research field is well-documented, but its impact on the number of citations received by research publications is not fully understood. This paper aims to fill this gap by examining the relationship between international collaboration and other factors and the number of citations for research publications. Using data from the Lebanese American University, the authors employ ordinary least squares estimation to analyze the impact of international collaboration on the number of citations while controlling for various publications, authorship, university, and socioeconomic factors. The results show that international collaboration has a statistically significant positive effect on the number of citations. Based on these findings, we recommend that institutions and researchers seeking to increase the number of citations for their publications should focus on building and maintaining strong international collaborations and publishing in high-quality journals. This paper provides evidence of the importance of international collaboration for researchers seeking to increase the reach and impact of their publications. It also provides insights into the relevance of other factors, such as the number of publications, age, and journal rank."
    },
    {
      title: "Does twitter economic uncertainty matter for wheat prices?",
      journal: "Economics Letters",
      date: "2024",
      link: "https://www.sciencedirect.com/science/article/abs/pii/S0165176523004895",
      abstract: "This paper investigates the relationship between wheat prices, energy prices (crude oil and natural gas), and economic uncertainty, represented by the Twitter Economic Uncertainty Index (TEU). Despite existing literature emphasizing the significant impact of energy costs on agricultural commodity prices, this research uncovers no significant short-run relationship between the price shocks in the crude oil or natural gas markets and wheat prices. Similarly, fluctuations in the TEU USA do not reliably predict changes in wheat prices. This paper enriches the field by utilizing time series econometric analysis with a Structural Vector Auto-Regressive model with an exogenous variable (SVARX), incorporating four endogenous variables: wheat, crude oil, natural gas, and economic uncertainty from mid-2011 to mid-2022, and an exogenous dummy variable to account for an existing structural break. The findings present new insights into the complex interplay between these variables within a single framework and expand our understanding of the impact of economic uncertainty derived from Twitter data on agricultural commodity prices. Policymakers and researchers are advised to continue exploring these relationships over different periods, geographical contexts, and events to strengthen food security strategies by gaining a nuanced understanding of agricultural commodity prices."
    },
    {
      title: "Is the Price of Ether Driven by Demand or Pure Speculation?",
      journal: "Computational Economics",
      date: "2024",
      link: "https://link.springer.com/article/10.1007/s10614-024-10658-6",
      abstract: "This research, utilizing weekly data from 2017 to 2023 (298 observations), seeks to answer the question, “Is the Price of Ether Driven by Demand or Pure Speculation?” It investigates the determinants of Ether price by focusing on the role of Gas price in Wei, Ethereum Network Utilization, and Bitcoin price. The study demonstrates that Network Utilization, indicative of demand, significantly influences Ether’s price, suggesting a demand-driven price dynamic over pure speculation. Conversely, Gas and Bitcoin prices exert a less pronounced impact. Despite the constraints of a specific timeframe and limited variables, the research provides crucial insights into Ether’s pricing dynamics. The revealed dependence of Ether’s price on actual network demand and utilization supports the argument that Ether exhibits commodity-like characteristics, contributing to the ongoing debate on Ether’s status as a commodity or a security. The utility of econometric methodologies, especially the SVAR model, is highlighted in exploring relationships within the Ethereum ecosystem. The study holds significant implications for stakeholders in the Ethereum ecosystem and the broader cryptocurrency market, and it encourages future research to consider additional price determinants and employ diverse econometric models."
    }
  ];

  const cardClasses = "p-6 bg-primary/20 hover:bg-primary rounded-xl transition-all cursor-pointer border border-primary/20";
  const skillClasses = "px-4 py-2 bg-white text-black font-bold rounded-lg transition-all hover:bg-text/90";

  const renderExpandableCard = (item: any, index: number, activeIndex: number | null, setActiveIndex: (index: number | null) => void) => (
    <AnimatedCard key={index}>
      <div 
        onClick={() => setActiveIndex(activeIndex === index ? null : index)}
        className={cardClasses}
      >
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-xl font-bold text-text">{item.title || item.degree}</h3>
            <p className="text-text/60">{item.company || item.institution || item.organization}</p>
          </div>
          <div className="flex items-center gap-4">
            <span className="text-text/60 text-sm">
              {item.period || item.date}
            </span>
            {item.details && (
              <motion.div
                animate={{ rotate: activeIndex === index ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDown className="text-text" />
              </motion.div>
            )}
          </div>
        </div>
        <AnimatePresence>
          {activeIndex === index && item.details && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="mt-4"
            >
              {Array.isArray(item.details) ? (
                <ul className="space-y-2">
                  {item.details.map((detail: string, i: number) => (
                    <li key={i} className="text-text/80 flex items-start">
                      <span className="text-text mr-2">•</span>
                      {detail}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-text/80">{item.details}</p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AnimatedCard>
  );

  return (
    <div className="min-h-screen bg-background py-24 px-4">
      <div className="container mx-auto max-w-5xl">
        {/* Name */}
        <section className="mb-16 text-center">
          <h1 className="text-6xl md:text-7xl font-bold text-text mb-6">
            Zein Alamah
          </h1>
          <p className="text-2xl text-text/80 text-center mb-6">
            Strategist | Economist | Data Analyst
          </p>
          <p className="text-xl text-text/80 text-center max-w-3xl mx-auto">
          I leverage my expertise in economics, finance, and data analysis to devise strategies that drive results and resolve critical organizational challenges.
          </p>
        </section>

        {/* Experience Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Professional Experience</h2>
          <div className="space-y-4">
            {experiences.map((exp, index) => renderExpandableCard(exp, index, activeExperience, setActiveExperience))}
          </div>
        </section>

        {/* Education Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Education</h2>
          <div className="space-y-4">
            {education.map((edu, index) => renderExpandableCard(edu, index, activeEducation, setActiveEducation))}
          </div>
        </section>

        {/* Certifications Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Certifications</h2>
          <div className="space-y-4">
            {certifications.map((cert, index) => renderExpandableCard(cert, index, activeCertification, setActiveCertification))}
          </div>
        </section>

        {/* Awards Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Awards & Recognition</h2>
          <div className="space-y-4">
            {awards.map((award, index) => (
              <AnimatedCard key={index}>
                <div className={cardClasses}>
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-bold text-text">{award.title}</h3>
                      <p className="text-text/80 mt-2">{award.description}</p>
                    </div>
                    <span className="text-text/60 text-sm">{award.year}</span>
                  </div>
                </div>
              </AnimatedCard>
            ))}
          </div>
        </section>

        {/* Skills Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Skills & Expertise</h2>
          <div className="space-y-8">
            <AnimatedCard>
              <div className={cardClasses}>
                <h3 className="text-xl font-bold text-text mb-4">Technical Skills</h3>
                <div className="flex flex-wrap gap-3">
                  {[ "Python (Intermediate)",
  "Stata (Advanced)",
  "Microsoft Office Suite (Word, Excel, PowerPoint)",
  "Econometric Modeling",
  "Data Analysis",
  "Time-Series Analysis",
  "Financial Modeling"].map((skill) => (
                    <span key={skill} className={skillClasses}>
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className={cardClasses}>
                <h3 className="text-xl font-bold text-text mb-4">Other Skills</h3>
                <div className="flex flex-wrap gap-3">
                  {['Strategic Planning', 'Research', 'Project Management', 'Negotiations', "Academic Writing", "Report Writing",
"Presentation Development", "Cross-Functional Collaboration"].map((skill) => (
                    <span key={skill} className={skillClasses}>
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </AnimatedCard>
          </div>
        </section>

        {/* Publications Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-text mb-6">Publications</h2>
          <div className="space-y-4">
            {publications.map((pub, index) => (
              <AnimatedCard key={index}>
                <ResumePublicationCard
                  publication={pub}
                  isExpanded={activePublication === index}
                  onToggle={() => setActivePublication(activePublication === index ? null : index)}
                />
              </AnimatedCard>
            ))}
          </div>
        </section>

        {/* Contact Section */}
        <section className="mb-12">
          <AnimatedCard>
            <div className="text-center">
              <h2 className="text-3xl font-bold text-text mb-8">Get in Touch</h2>
              <p className="text-text/60 text-lg max-w-2xl mx-auto mb-12">
            Have a question, or just want to say hi? Let’s connect.
            </p>
              <div className="flex flex-wrap justify-center gap-6">
                <a 
                  href="mailto:zein@zeinalamah.com"
                  className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
                >
                  <Mail size={20} />
                  Email Me
                </a>
                <a 
                  href="https://www.linkedin.com/in/zein-alamah/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
                >
                  <Linkedin size={20} />
                  Connect on LinkedIn
                </a>
                <a 
                  href="https://x.com/zein_alamah"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
                >
                  <FaXTwitter size={20} />
                  Follow on X
                </a>
                <a 
                  href="https://drive.google.com/file/d/1DYcLDO0RwY9tU0tN9rPristpfR660OzP/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-6 py-3 bg-primary/20 hover:bg-text text-text hover:text-black rounded-lg transition-colors"
                >
                  <Download size={20} />
                  Download CV
                </a>
              </div>
            </div>
          </AnimatedCard>
        </section>
      </div>
    </div>
  );
};

export default Resume;