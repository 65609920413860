import { Link } from 'react-router-dom';
import { LazyImage } from './LazyImage';
import { SocialShare } from './SocialShare';
import { CardMeta } from './CardMeta';
import { calculateReadTime } from '../utils/readTime';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  date: string;
  category: string;
  image: string;
  tags: string[];
  content?: string;
}

interface BlogCardProps {
  post: BlogPost;
  showSocial?: boolean;
}

export const BlogCard = ({ post, showSocial = false }: BlogCardProps) => {
  if (!post) return null;

  const postUrl = `${window.location.origin}/blog/${post.id}`;
  const readTime = calculateReadTime(post.content || '');

  return (
    <article className="bg-primary/5 rounded-xl overflow-hidden border border-primary/20 hover:border-primary transition-colors h-full flex flex-col">
      <Link to={`/blog/${post.id}`} className="block group">
        <div className="relative h-48 overflow-hidden">
          <LazyImage
            src={post.image}
            alt={post.title}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute top-4 left-4 bg-primary/90 text-text px-3 py-1 rounded-full text-sm">
            {post.category}
          </div>
        </div>
      </Link>
      
      <div className="p-6 flex flex-col flex-1">
        <Link to={`/blog/${post.id}`} className="block group mb-4">
          <h2 className="text-xl font-bold text-text mb-3 group-hover:text-primary transition-colors line-clamp-2">
            {post.title}
          </h2>
          <p className="text-text/60 line-clamp-3">
            {post.excerpt}
          </p>
        </Link>
        
        <div className="mt-auto pt-4">
          <CardMeta
            date={post.date}
            readTime={readTime}
            tags={post.tags}
          />

          {showSocial && (
            <div className="mt-4 pt-4 border-t border-primary/20">
              <SocialShare
                url={postUrl}
                title={post.title}
                summary={post.excerpt}
              />
            </div>
          )}
        </div>
      </div>
    </article>
  );
};