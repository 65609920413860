import { Link } from 'react-router-dom';
import { LazyImage } from './LazyImage';
import { SocialShare } from './SocialShare';
import { CardMeta } from './CardMeta';
import { calculateReadTime } from '../utils/readTime';

interface Project {
  id: string;
  title: string;
  description: string;
  technologies: string[];
  image: string;
  github?: string;
  demo?: string;
  date: string;
  content: string;
}

interface ProjectCardProps {
  project: Project;
  showSocial?: boolean;
}

export const ProjectCard = ({ project, showSocial = false }: ProjectCardProps) => {
  const projectUrl = `${window.location.origin}/projects/${project.id}`;
  const readTime = calculateReadTime(project.content);

  return (
    <article className="bg-primary/5 rounded-xl overflow-hidden border border-primary/20 hover:border-primary transition-colors h-full flex flex-col">
      <Link to={`/projects/${project.id}`} className="block group">
        <div className="relative h-48 overflow-hidden">
          <LazyImage
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute top-4 left-4 bg-primary/90 text-text px-3 py-1 rounded-full text-sm">
            Project
          </div>
        </div>
      </Link>
      
      <div className="p-6 flex flex-col flex-1">
        <div className="mb-4">
          <Link 
            to={`/projects/${project.id}`}
            className="text-xl font-bold text-text hover:text-primary transition-colors block mb-3"
          >
            {project.title}
          </Link>
          <p className="text-text/60 line-clamp-3">
            {project.description}
          </p>
        </div>
        
        <div className="mt-auto pt-4">
          <CardMeta
            date={project.date}
            readTime={readTime}
            tags={project.technologies}
          />

          {showSocial && (
            <div className="mt-4 pt-4 border-t border-primary/20">
              <div className="flex justify-between items-center">
                <SocialShare
                  url={projectUrl}
                  title={project.title}
                  summary={project.description}
                />
                
                <div className="flex gap-4">
                  {project.github && (
                    <a
                      href={project.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-text/60 hover:text-primary transition-colors"
                      onClick={(e) => e.stopPropagation()}
                    >
                      View Code
                    </a>
                  )}
                  {project.demo && (
                    <a
                      href={project.demo}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-text/60 hover:text-primary transition-colors"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Live Demo
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};