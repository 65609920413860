import { useState } from 'react';
import { PublicationCard } from '../components/PublicationCard';
import { publications } from '../data/publications';

const Publications = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [showMoreTags, setShowMoreTags] = useState(false);

  // Count the frequency of each tag
  const tagFrequency = publications
    .flatMap(pub => pub.tags)
    .reduce<Record<string, number>>((acc, tag) => {
      acc[tag] = (acc[tag] || 0) + 1;
      return acc;
    }, {});

  // Sort tags by frequency
  const sortedTags = Object.keys(tagFrequency).sort((a, b) => tagFrequency[b] - tagFrequency[a]);

  // Add "All" to the beginning of the sorted tags
  const categories = ['All', ...sortedTags];
  const maxTagsToShowInitially = 5; // Default number of tags to show
  const maxTagsToShowExpanded = 15; // Number of tags to show when expanded

  // Determine which tags to display
  const displayedCategories = showMoreTags
    ? categories.slice(0, maxTagsToShowExpanded + 1)
    : categories.slice(0, maxTagsToShowInitially + 1);

  // Filter publications based on selectedCategory and searchQuery
  const filteredPublications = publications.filter(pub => {
    const matchesCategory =
      selectedCategory === 'All' ||
      (selectedCategory === 'Academic' && pub.type === 'academic') ||
      (selectedCategory === 'Non-Academic' && pub.type === 'non-academic') ||
      pub.tags.includes(selectedCategory);
    const matchesSearch =
      pub.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pub.abstract.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pub.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesCategory && matchesSearch;
  });

  return (
    <div className="min-h-screen bg-background py-24 px-4">
      <div className="container mx-auto max-w-6xl">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-text mb-4">Publications</h1>
          <p className="text-text/60 text-lg max-w-2xl mx-auto">
            Research publications & Articles.
          </p>
        </div>

        {/* Search and Filter Section */}
        <div className="mb-12 space-y-6">
          <input
            type="text"
            placeholder="Search publications..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-6 py-3 bg-primary/5 border border-primary/20 rounded-lg focus:outline-none focus:border-primary transition-colors text-text"
          />

          <div className="flex flex-wrap gap-4">
            {displayedCategories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  selectedCategory === category
                    ? 'bg-primary text-text'
                    : 'bg-primary/20 text-text hover:bg-primary/30'
                }`}
              >
                {category}
              </button>
            ))}
            {categories.length > maxTagsToShowInitially + 1 && (
              <button
                onClick={() => setShowMoreTags(!showMoreTags)}
                className="px-4 py-2 rounded-lg bg-primary/20 text-text hover:bg-primary/30 transition-colors"
              >
                {showMoreTags ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
        </div>

        {/* Publications Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPublications.map((publication) => (
            <a
              key={publication.id}
              href={publication.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block hover:shadow-lg transition-shadow"
            >
              <PublicationCard publication={publication} showSocial />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Publications;
