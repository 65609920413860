import { useParams } from 'react-router-dom';
import { AnimatedCard } from '../components/AnimatedCard';
import { SocialShare } from '../components/SocialShare';
import { LazyImage } from '../components/LazyImage';
import { projects } from '../data/projects';
import { Github, ExternalLink } from 'lucide-react';

const ProjectDetails = () => {
  const { id } = useParams();
  const project = projects.find((p) => p.id === id);

  if (!project) {
    return (
      <div className="min-h-screen bg-background py-24 px-4">
        <div className="container mx-auto max-w-4xl text-center">
          <h1 className="text-4xl font-bold text-text mb-4">Project Not Found</h1>
          <p className="text-text/60">
            The project you're looking for doesn't exist.
          </p>
        </div>
      </div>
    );
  }

  const projectUrl = window.location.href;

  return (
    <div className="min-h-screen bg-background py-24 px-4">
      <article className="container mx-auto max-w-4xl">
        <AnimatedCard>
          <header className="mb-8">
            <h1 className="text-4xl md:text-5xl font-bold text-text mb-6">
              {project.title}
            </h1>

            <div className="flex flex-wrap items-center gap-6 text-text/60 mb-6">
              <div className="flex gap-4">
                {project.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:text-primary transition-colors"
                  >
                    <Github size={20} />
                    View Code
                  </a>
                )}
                {project.demo && (
                  <a
                    href={project.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:text-primary transition-colors"
                  >
                    <ExternalLink size={20} />
                    Live Demo
                  </a>
                )}
              </div>
              <div className="flex-1" />
              <SocialShare
                url={projectUrl}
                title={project.title}
                summary={project.description}
              />
            </div>

            <div className="flex flex-wrap gap-2 mb-8">
              {project.technologies.map((tech) => (
                <span
                  key={tech}
                  className="px-3 py-1 rounded-full bg-primary/10 text-text/80 text-sm"
                >
                  {tech}
                </span>
              ))}
            </div>
          </header>

          {/* Updated Image Container */}
          <div className="mb-8 rounded-xl overflow-hidden bg-primary/10 flex justify-center items-center">
  <LazyImage
    src={project.image}
    alt={project.title}
    className="max-w-full max-h-[400px] w-auto h-auto object-contain"
  />
</div>



          <div
            className="prose prose-lg prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: project.content }}
          ></div>

          <footer className="mt-12 pt-8 border-t border-primary/20">
            <SocialShare
              url={projectUrl}
              title={project.title}
              summary={project.description}
            />
          </footer>
        </AnimatedCard>
      </article>
    </div>
  );
};

export default ProjectDetails;
